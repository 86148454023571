import React from 'react';
import InfoPage from '../InfoPage';
import { useIntl } from 'react-intl';
import { ExternalLink } from '../../../components';
import { useConfiguration } from '../../../context/configurationContext';
import { LOCALE, getCurrentLocale } from '../../../util/locale';
import TermsContentBrazil from './TermsContentBrazil';

const LOCALE_TO_TERMS_OF_SERVICE_CONFIG_KEY = {
  [LOCALE.POLAND]: 'tosUrlPL',
  [LOCALE.SOUTH_AFRICA]: 'tosUrlZA',
  [LOCALE.UNITED_STATES]: 'tosUrlUS',
};

// This "content-only" component can be used in modals etc.
const TermsOfServiceContent = props => {
  const { className } = props;

  const config = useConfiguration();

  const locale = getCurrentLocale();

  const tosURLContent = config[LOCALE_TO_TERMS_OF_SERVICE_CONFIG_KEY[locale]];

  return (
    <div className={className}>
      {tosURLContent ? (
        <ExternalLink href={tosURLContent}>{tosURLContent}</ExternalLink>
      ) : (
        <TermsContentBrazil />
      )}
    </div>
  );
};

const TermsOfServicePage = () => {
  const intl = useIntl();

  return (
    <InfoPage
      title={intl.formatMessage({ id: 'General.termsOfService' })}
      contentTitle={intl.formatMessage({ id: 'TermsOfServicePage.contentTitle' })}
      currentPage="TermsOfServicePage"
    >
      <TermsOfServiceContent />
    </InfoPage>
  );
};

export { TermsOfServiceContent };

export default TermsOfServicePage;
